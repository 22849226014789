@import '~@selectize/selectize/dist/css/selectize.bootstrap5.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/dist/css/bootstrap.css";

:root {
    --srp-text-color: #f8f9fa; // color of .text-light
    --srp-text-color2: #6c757d;

    --srp-bg-color: #212529; // color of .bg-dark
    --srp-bg-color-2: #2c3034; // lighter, color of odd table rows

    // Colors were taken from the Bootswatch Cyborg theme.
    --srp-link-color: #2a9fd6; // also used in .srp-external-link
    --srp-link-color-hover: #227fab;

    --srp-max-width: 1600px;
    --srp-footer-height: 51px;
}

// Layout

html {
    // for sticky footer
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: calc(var(--srp-footer-height) + 19px);
    max-width: var(--srp-max-width);
}

.srp-page-footer {
    border-top: 1px solid grey;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: calc(min(var(--srp-max-width), 100%) - var(--bs-gutter-x));
    max-height: var(--srp-footer-height);
    overflow-y: auto;

    .eve {
        position: relative;
        z-index: 2;
        background-color: var(--srp-bg-color);
    }

    .github {
        position: absolute;
        z-index: 1;
        right: 0;
        a {
            text-decoration: none;
        }
        img {
            vertical-align: text-bottom;
        }
    }
}

// General

a {
    color: var(--srp-link-color);
    &:hover {
        color: var(--srp-link-color-hover);
    }
}

.srp-external-link {
    // bi bi-box-arrow-up-right
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%232a9fd6" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/><path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/></svg>');
    background-position: 100%;
    background-size: 0.825em;
    background-repeat: no-repeat;
    padding-right: 1em;
}

.srp-cursor-pointer {
    cursor: pointer;
}

.srp-bg-dark-2 {
    background-color: var(--srp-bg-color-2);
}

.srp-text-with-title {
    text-decoration: underline;
    text-decoration-style: dotted;
}

.srp-text-no-wrap {
    white-space: nowrap;
}

// Components

.srp-copy-text:hover {
    filter: brightness(0.7);
}

// Libraries

.form-control,
.form-control:focus,
.form-select {
    color: var(--srp-text-color);
    background-color: var(--srp-bg-color-2);
}
.form-select {
    // $form-select-indicator
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='lightgrey' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
}
.form-text,
.form-control::placeholder {
    color: var(--srp-text-color2);
}

.popover {
    background-color: var(--srp-bg-color);
    border-color: grey;
    .popover-body {
        color: var(--srp-text-color);
    }
    .popover-header {
        background-color: var(--srp-bg-color-2);
        color: var(--srp-text-color);
    }
}

.selectize-control {
    .selectize-input {
        background-color: var(--srp-bg-color-2);
        input {
            color: var(--srp-text-color);
        }
    }
}

.pagination {
    --bs-pagination-bg: var(--srp-bg-color);
    --bs-pagination-color: var(--srp-text-color);
    --bs-pagination-hover-bg: var(--srp-bg-color-2);
    --bs-pagination-hover-color: var(--srp-text-color);
    --bs-pagination-focus-bg: var(--srp-bg-color-2);
    --bs-pagination-focus-color: var(--srp-text-color);
    --bs-pagination-disabled-bg: var(--srp-bg-color);
    --bs-pagination-disabled-color: var(--bs-gray-600);
    --bs-pagination-border-color: var(--srp-bg-color-2);
    --bs-pagination-hover-border-color: var(--srp-bg-color-2);
    --bs-pagination-disabled-border-color: var(--srp-bg-color-2);
}

// Pages

.page-submit {
    a.kill-mail {
        text-decoration-style: dotted;
    }
    .popover.kill-mail {
        max-width: 100%;
    }
}

.page-request {
    .data-changes .border-bottom,
    .data-modifier.border-bottom {
        border-bottom-color: var(--bs-table-border-color) !important;
    }
    .data-changes {
        .data td {
            padding-top: 1rem;
        }
        .border-bottom td {
            padding-bottom: 1rem;
        }
    }
}

.page-admin-permissions {
    th:nth-child(1) {
        width: 100px;
    }
}
